import React, { useState } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaEnvelope } from "react-icons/fa";
import { forgotPass } from "../utils/UserFunctions";

const ForgotPassword = () => {
    const [FormData, setFormData] = useState({
        email: "",
    });

    const { email } = FormData;

    const navigate = useNavigate();

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        forgotPass(email);
        navigate("/sign-in");
    };

    return (
        <Container>
            <h2>
                Olvide mi Password <FaEnvelope />
            </h2>
            <Row className='signinbody'>
                <Col sm={12} md={8} lg={6} xxl={4}>
                    <Form onSubmit={onSubmit}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Correo Electronico</Form.Label>
                            <Form.Control
                                type='email'
                                id='email'
                                value={email}
                                placeholder='Ingresa le correo con que registraste tu cuenta'
                                onChange={onChange}
                            />
                            <Form.Text className='text-muted fst-italic'>
                                nunca compartiremos su correo electrónico con
                                nadie más.
                            </Form.Text>
                        </Form.Group>

                        <Button
                            variant='success'
                            style={{ width: "100%", marginTop: "3em" }}
                            type='submit'
                        >
                            restablecer contraseña link
                        </Button>
                        <div
                            style={{ padding: "2em 0 2em 0", textAlign: "end" }}
                        ></div>
                    </Form>
                    <div
                        style={{ padding: "2em 0 2em 0", textAlign: "center" }}
                    ></div>
                </Col>
            </Row>
        </Container>
    );
};

export default ForgotPassword;
