import React, { useState, useContext } from "react";
import { ContextTheme } from "../context/ContextTheme";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  ListGroup,
  Badge,
} from "react-bootstrap";
import { getAuth } from "firebase/auth";
import { BiDuplicate } from "react-icons/bi";
import { FaListUl } from "react-icons/fa";
import { TiEdit } from "react-icons/ti";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";

// import { useNavigate } from "react-router-dom";
import { createCat, delCat, updateCat } from "../utils/categoriaFunctions";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";

const Categorias = () => {
  const auth = getAuth();
  const { users } = useContext(ContextTheme);
  const { categorias } = useContext(ContextTheme);
  const { catalago } = useContext(ContextTheme);
  const user = users.find((x) => x.id.toString() === auth.currentUser.uid);

  const userId = user.id;
  // const navigate = useNavigate();
  const [FormData, setFormData] = useState({
    categoria: "",
  });
  const [changeCat, setChangeCat] = useState("");
  const [newCategoria, SetNewCategoria] = useState("");
  const [newCatId, setNewCatId] = useState("");

  const { categoria } = FormData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value.toUpperCase(),
    }));
  };
  const onChange1 = (e) => {
    SetNewCategoria(e.target.value.toUpperCase());
  };

  const editCategoria = (name) => {
    setChangeCat(true);
    SetNewCategoria(name);
    setTimeout(() => {
      document.getElementById("newcategoria").focus();
    }, "500");
  };

  const delCategoria = (id, catego) => {
    const ctasregistradas = catalago.find((x) => x.data.categoria === catego);

    if (!ctasregistradas) {
      toast.error("Borrando categoria ");
      delCat(id);
    } else {
      toast.error("No se Puede Borrar la Categoria tienes cuentas Activas... ");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    createCat(FormData, userId);
  };
  const onsubmitcat = async (e) => {
    e.preventDefault();
    updateCat(newCatId, newCategoria);
    SetNewCategoria("");
    setChangeCat(false);
  };

  return (
    <>
      <Container>
        <h2>
          Categorias <BiDuplicate style={{ marginLeft: "1em" }} />
        </h2>
        <Link className='btn btn-light my-3' to='/'>
          Go Back
        </Link>

        <Row style={{ justifyContent: "center" }}>
          <Col sm={12} md={8} lg={6} xxl={4}>
            {!changeCat ? (
              <Form onSubmit={onSubmit}>
                <Form.Group className='mb-3'>
                  <Form.Label>Categoria Nueva</Form.Label>
                  <Form.Control
                    type='categoria'
                    id='categoria'
                    value={categoria}
                    placeholder='Agregar nueva categoria'
                    onChange={onChange}
                  />
                </Form.Group>

                <Button
                  variant='success'
                  style={{ width: "100%", marginTop: "1em" }}
                  type='submit'
                >
                  Agregar nueva Categoria
                </Button>
              </Form>
            ) : (
              <Form onSubmit={onsubmitcat}>
                <Form.Group className='mb-3'>
                  <Form.Label>Modificar Categoria</Form.Label>
                  <Form.Control
                    type='newcategoria'
                    id='newcategoria'
                    value={newCategoria}
                    onChange={onChange1}
                  />
                </Form.Group>

                <Button
                  variant='success'
                  style={{ width: "100%", marginTop: "1em" }}
                  type='submit'
                >
                  Aceptar Cambios
                </Button>
              </Form>
            )}
          </Col>
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <h2 style={{ marginTop: "1.5em" }}>
            Lista de Categorias <FaListUl style={{ marginLeft: "1em" }} />
          </h2>
          <Col sm={12} md={8} lg={6} xxl={4}>
            <ListGroup>
              {categorias.map((cat) => (
                <ListGroup.Item
                  key={cat.id}
                  as='li'
                  className='d-flex justify-content-between align-items-start'
                >
                  <div className='ms-2 me-auto'>
                    <div className='fw-bold'>{cat.data.categoria}</div>
                  </div>
                  <Badge
                    bg='success'
                    pill
                    style={{
                      marginRight: "1em",
                      fontSize: "1em",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setNewCatId(cat.id);
                      editCategoria(cat.data.categoria);
                    }}
                  >
                    <TiEdit />
                  </Badge>
                  <Badge
                    bg='danger'
                    pill
                    style={{
                      marginRight: "1em",
                      fontSize: "1em",
                      cursor: "pointer",
                    }}
                    onClick={() => delCategoria(cat.id, cat.data.categoria)}
                  >
                    <RiDeleteBinLine />
                  </Badge>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Categorias;
