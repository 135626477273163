import React from "react";

const SocialMedia = () => {
  return (
    <section className='redes-sociales'>
      <div className='contenedor'>
        <a
          className='linkedln'
          rel='noopener noreferrer'
          href='#!'
          //   target='_blank'
        >
          <i className='fab fa-linkedin'></i>
        </a>
        <a
          className='facebook'
          rel='noopener noreferrer'
          href='https://www.facebook.com/profile.php?id=100083307700773'
          target='_blank'
        >
          <i className='fab fa-facebook-square'></i>
        </a>

        <a
          className='instagram'
          href='https://www.instagram.com/friedagallery.com2/'
          target='_blank'
        >
          <i className='fab fa-instagram-square'></i>
        </a>
      </div>
    </section>
  );
};

export default SocialMedia;
