import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import ProfilePic from "../images/imagehere.png";
import { Container, Form, Col, Row, Button, Image } from "react-bootstrap";
import { createCliente, setpicfiles } from "../utils/clientesFunctions";
import { v4 as uuidv4 } from "uuid";
import ClientesList from "../components/ClientesList";

const CrearCuenta = () => {
  const [FormData, setFormData] = useState({
    nombreCliente: "",
    cuentaImage: "",
    cuentaImgRef: "",
  });

  const { nombreCliente } = FormData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const [preview, setPreview] = useState("");
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");

  const myPic = useRef();

  const submitHandler = async (e) => {
    e.preventDefault();
    createCliente(FormData);
    setPreview("");
    setProgress(0);
    setFormData((prevState) => ({
      nombreCliente: "",
      cuentaImage: "",
      cuentaImgRef: "",
    }));
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setpicfiles(file, fileName, setProgress, setFormData);
  };

  return (
    <>
      <Container>
        <Row className='justify-content-md-center text-center'>
          <Col xs={12} md={12} xl={8}>
            <Link className='btn btn-light my-3' to='/'>
              Go Back
            </Link>
            <h2 className='my-3 titlecategoria'>Agregar un Cliente</h2>
            <Form onSubmit={submitHandler}>
              <Form.Group>
                <Form.Label>
                  {preview ? (
                    <Image
                      fluid
                      style={{
                        borderRadius: "30px",
                        maxHeight: "7em",
                        objectFit: "cover",
                      }}
                      onClick={() => {
                        setFile(null);
                      }}
                      src={preview}
                    />
                  ) : (
                    <Button
                      onClick={(event) => {
                        event.preventDefault();
                        myPic.current.click();
                      }}
                      // id='propic'
                    >
                      <Image
                        style={{
                          maxHeight: "7em",
                          objectFit: "cover",
                        }}
                        fluid
                        src={ProfilePic}
                        alt=''
                      />
                    </Button>
                  )}
                </Form.Label>
                <Form.Control
                  ref={myPic}
                  accept='image/*'
                  style={{ display: "none" }}
                  type='file'
                  id='file'
                  name='file'
                  onChange={(event) => {
                    const file = event.target.files[0];

                    if (file && file.type.substr(0, 5) === "image") {
                      setFile(file);
                      setFileName(`${uuidv4()}-${file.name}`);
                    } else {
                      setFile(null);
                    }
                    if (file) {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        const Base64 = reader.result;
                        setPreview(Base64);
                      };
                      reader.readAsDataURL(file);
                    } else {
                      setPreview(null);
                    }
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <progress value={progress} max='100' />
              </Form.Group>
              <Button onClick={(e) => handleUpload(e)} variant='info'>
                Agregar Imagen
              </Button>

              <Form.Group
                style={{ padding: ".5em 0" }}
                controlId='nombreCliente'
              >
                <Form.Control
                  type='text'
                  placeholder='Nombre del Cliente'
                  value={nombreCliente}
                  onChange={onChange}
                ></Form.Control>
              </Form.Group>

              <Button
                style={{ width: "100%" }}
                className='my-1'
                type='submit'
                variant='primary'
              >
                Enviar
              </Button>
            </Form>
          </Col>
        </Row>
        <ClientesList />
      </Container>
    </>
  );
};

export default CrearCuenta;
