import React from "react";
import { FcCopyright } from "react-icons/fc";
import { AiOutlineTrademark } from "react-icons/ai";

const Footer = () => {
  return (
    <footer className='animated-gradient footer'>
      <p className='footerP'>
        <FcCopyright /> {new Date().getFullYear()} FRIEDA GALLERY
      </p>
      <p className='footerP2'>
        Saint Louis, MO | All Right Reserved | Photo By Brittney Bulter
      </p>
      <p className='footerP2'>
        <AiOutlineTrademark />
        Designed by Salvador Silva
      </p>
    </footer>
  );
};

export default Footer;
