import {
  addDoc,
  collection,
  doc,
  deleteDoc,
  // updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase.confirg";

export const createEvent = async (FormData, userId) => {
  const { title, description, start, end } = FormData;
  const formDataCopy = {
    title,
    description,
    start,
    end,
    userId,
    timestamp: serverTimestamp(),
  };
  await addDoc(collection(db, "eventos"), formDataCopy);
};

// export const updateCat = async (catId, newCategoria) => {
//     const docRef = doc(db, "eventos", catId);

//     await updateDoc(docRef, {
//         categoria: newCategoria,
//     })
//         .then(() => {
//             toast("Categoria Modificada ...");
//         })
//         .catch((err) => {
//             toast.error("No se pudo Registrar Categoria", err);
//         });
// };

export const delEvent = async (EventId) => {
  deleteDoc(doc(db, "eventos", EventId));
};
