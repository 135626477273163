import React, { useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import { ContextTheme } from "../context/ContextTheme";
import { Container, Row } from "react-bootstrap";
import Product from "../components/Product";

const CatalagoDisplay = () => {
  const { catalago } = useContext(ContextTheme);
  const location = useLocation();

  // location.state ? console.log("si") : console.log("no");
  // let categoriaId = 0;
  // if (location.state) {
  //   const { categoria } = location.state;
  //   categoriaId = categoria.data.categoria;
  // } else {
  //   const categoria = "";
  // }
  const { categoria } = location.state;
  // const categoriaId = categoria.data.categoria;
  const cuentas = catalago.filter(
    (x) => x.data.categoria.toString() === categoria
  );
  // console.log(cuentas);

  return (
    <div className='animated-gradient '>
      <Container>
        <h2>Frieda's {categoria}</h2>
        <Link
          className='btn btn-light my-3'
          state={{ categoria: categoria }}
          to='/'
        >
          Go Back
        </Link>

        <Row>
          {cuentas.map((product) => {
            return (
              <Product
                key={product.id}
                product={product}
                categoria={categoria}
              />
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default CatalagoDisplay;
