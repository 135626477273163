import React from "react";
import { Link } from "react-router-dom";
import { Card, Col } from "react-bootstrap";

// import Rating from "./Rating";

const Product = ({ product, categoria }) => {
  // const [mostra, setMostrar] = useState(false);

  const displayTitle = () => {
    const parrafo = document.getElementById(`${product.id}`);
    parrafo.classList.toggle("display");
  };
  const displayTitlenot = () => {
    const parrafo = document.getElementById(`${product.id}`);
    parrafo.classList.toggle("display");
  };

  return (
    <Col xs={6} md={4} style={{ alignItems: "center", display: "flex" }}>
      <Card className='prodectCard'>
        <Link to={`/cuenta/${product.id}`} state={categoria}>
          <Card.Img
            // style={{ maxHeight: "14em" }}
            onMouseOver={displayTitle}
            onMouseLeave={displayTitlenot}
            className='productImage'
            src={product.data.cuentaImage}
            // variant='top'
          />
          {/* <p className='titleparrafo'>{product.title}</p> */}
        </Link>
        <Card.Body className='productcardbody'>
          <Link
            to={`/cuenta/${product.id}`}
            state={categoria}
            style={{ textAlign: "center" }}
          >
            <Card.Title>
              <p id={product.id} className='titleparrafo display'>
                {product.data.especialidad}
              </p>
            </Card.Title>
          </Link>
          {/* <Card.Text as='div'>
                        <Rating
                            value={product.rating}
                            text={`${product.numReviews} reviews`}
                        />
                    </Card.Text>
                    <Card.Text as='h5'>{product.duracion} dls</Card.Text> */}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default Product;
