import React, { useState } from "react";
import { Nav, Navbar, Offcanvas, Image, Dropdown } from "react-bootstrap";
import Logo from "../images/Frieda_logo-gris.png";
import { FaUser } from "react-icons/fa";
import { ContextTheme } from "../context/ContextTheme";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { getrole } from "../utils/UserFunctions";
import ProfilePic from "../images/profile.jpg";

const Header = () => {
  const auth = getAuth();
  let user = "";
  const { users } = useContext(ContextTheme);
  auth.currentUser == null
    ? (user = null)
    : (user = users.find((x) => x.id === auth.currentUser.uid));
  // const user = users.find((x) => x.id === auth.currentUser.uid);
  const navigate = useNavigate();
  // const userFoto = user ? user.data.userImage : null;
  let userFoto = null;

  const [userRole, setUserRole] = useState(0);

  if (user) {
    if (user.data.userImage !== "") {
      userFoto = user.data.userImage;
    }
    const id = user.id;
    getrole(id, setUserRole);
  }

  const signout = () => {
    auth.signOut();
    navigate("/");
    window.location.reload();
  };

  return (
    <header className='header animated-gradient '>
      {
        <>
          <Navbar expand='md' className='mb-3'>
            <Navbar.Brand href='/'>
              <img
                src={Logo}
                className='headerlogo '
                alt='Product & services logo'
              />
            </Navbar.Brand>
            {user ? (
              <>
                <div className='  header-name-picture'>
                  <Link
                    to={"/profile"}
                    style={
                      userFoto && {
                        pointerEvents: "none",
                      }
                    }
                  >
                    <Image
                      className={
                        userFoto === null
                          ? "header-user-pic blink"
                          : "header-user-pic "
                      }
                      src={userFoto === null ? ProfilePic : userFoto}
                      alt='Agrega to Foto'
                    ></Image>
                  </Link>
                  <Dropdown>
                    <Dropdown.Toggle
                      className='header-user-nane'
                      variant='light'
                      id='dropdown-basic'
                    >
                      {user.data.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href='/profile'>Profile</Dropdown.Item>

                      {user && userRole === 1 && (
                        <>
                          <Dropdown.Item href='/admin/categorias'>
                            Categorias
                          </Dropdown.Item>
                          <Dropdown.Item href='/admin/catalago'>
                            Catalago
                          </Dropdown.Item>
                          <Dropdown.Item href='/admin/info-precio-list'>
                            Info & Precios Lista
                          </Dropdown.Item>
                          <Dropdown.Item href='/admin/clientes'>
                            Nuestros Clientes
                          </Dropdown.Item>
                          <Dropdown.Item href='/admin/add-galeria'>
                            Agregar a Galeria
                          </Dropdown.Item>
                        </>
                      )}
                      <Dropdown.Item onClick={signout}>
                        cerrar Seción
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            ) : (
              <Nav.Link className='headerlink' href='/sign-in'>
                Iniciar sesión
                <FaUser
                  style={{
                    marginLeft: ".5em",
                    fontSize: "1em",
                  }}
                />{" "}
              </Nav.Link>
            )}
            <Navbar.Toggle aria-controls='menu' />
            <Navbar.Offcanvas
              id='menu'
              aria-labelledby='menu-offcanvas'
              placement='end'
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id='menu-offcanvas'>Menu</Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body>
                <Nav className='justify-content-end flex-grow-1'>
                  <Nav.Link className='headerMenulink' href='/quienes-somos'>
                    Bio
                  </Nav.Link>

                  {/*****projecto de calendario funcional ********* */}

                  {/* <Nav.Link className='headerMenulink' href='/calendar'>
                    Calendar
                  </Nav.Link> */}

                  {/****projecto de calendario funcional ********* */}

                  <Nav.Link className='headerMenulink' href='/info-precios'>
                    Contact Me
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Navbar>
        </>
      }
    </header>
  );
};

export default Header;
