import {
    addDoc,
    collection,
    doc,
    deleteDoc,
    updateDoc,
    serverTimestamp,
} from "firebase/firestore";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
} from "firebase/storage";
import { db } from "../firebase.confirg";
import { toast } from "react-toastify";

const storage = getStorage();

export const createCuenta = async (FormData) => {
    console.log(FormData);
    const formDataCopy = {
        ...FormData,
        timestamp: serverTimestamp(),
    };
    await addDoc(collection(db, "catalago"), formDataCopy);
};

export const updateCuenta = async (
    cuentaId,
    userId,
    especialidad,
    categoria,
    duracion,
    dirigido,
    objetivo
) => {
    const docRef = doc(db, "catalago", cuentaId);

    await updateDoc(docRef, {
        userId,
        especialidad,
        categoria,
        duracion,
        dirigido,
        objetivo,
        timestamp: serverTimestamp(),
    })
        .then(() => {
            toast("Cuenta Modificada ...");
        })
        .catch((err) => {
            toast.error("No se pudo Actualizar la Cuenta...", err);
        });
};

export const delCuenta = async (newCatId) => {
    console.log(newCatId);
    await deleteDoc(doc(db, "catalago", newCatId));
    toast("Categoria Borrada ...");
};

export const updateCatalagoPic = (fileName, file, cuentaId, setProgress) => {
    const metadata = {
        contenType: "image/*",
    };
    const storageRef = ref(storage, `cursosImages/${fileName}`);

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
                case "paused":
                    console.log("Upload is paused");
                    break;
                case "running":
                    console.log("Upload is running");
                    break;
                default:
            }
        },
        (error) => {
            switch (error.code) {
                case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    break;
                case "storage/canceled":
                    // User canceled the upload
                    break;

                // ...

                case "storage/unknown":
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                default:
            }
        },
        () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                // updateProfile(auth.currentUser, {
                //     photoURL: downloadURL,
                // });
                const updateCuentaImage = async () => {
                    const docRef = doc(db, "catalago", cuentaId);

                    const prodPayload = {
                        cuentaImage: downloadURL,
                        cuentaImgRef: fileName,
                    };

                    await updateDoc(docRef, prodPayload)
                        .then(() => {
                            toast("Fotografia de Cuenta Actualizada");
                        })
                        .catch((err) => {
                            toast.err("Algo salio mal ...", err);
                        });
                };
                updateCuentaImage();
            });
        }
    );
};

export const setpicfiles = (file, fileName, setProgress, setFormData) => {
    const metadata = {
        contenType: "image/*",
    };
    const storageRef = ref(storage, `cursosImages/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
                case "paused":
                    console.log("Upload is paused");
                    break;
                case "running":
                    console.log("Upload is running");
                    break;
                default:
            }
        },
        (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    break;
                case "storage/canceled":
                    // User canceled the upload
                    break;

                // ...

                case "storage/unknown":
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                default:
            }
        },
        () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setFormData((prevState) => ({
                    ...prevState,
                    cuentaImage: downloadURL,
                    cuentaImgRef: fileName,
                }));
            });
        }
    );
};

export const delOldPic = (ctaOldPic) => {
    if (ctaOldPic === "") {
        toast("No exite foto de perfil");
    } else {
        try {
            deleteObject(ctaOldPic);
            toast("Borrando foto de Perfil");
        } catch (error) {
            toast.error("no se pudo borrar la foto..");
        }
    }
};
