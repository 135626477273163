import React, { useContext, useState } from "react";
import { ContextTheme } from "../context/ContextTheme";
import {
  Col,
  Image,
  ListGroup,
  Button,
  Container,
  Carousel,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import Rating from "../components/Rating";
import AddPicturesGallery from "../components/AddPicturesGallery";
import profile from "../images/profile.jpg";
import imagehere from "../images/logo_lower_BD.png";
import { updaterating } from "../utils/reviewfunctions";
import { FaUserPlus } from "react-icons/fa";

const CuentaScreen = ({ match }) => {
  const auth = getAuth();
  let user = "";
  const { catalago } = useContext(ContextTheme);
  const { reviews } = useContext(ContextTheme);
  const { fotosgaleria } = useContext(ContextTheme);
  const { users } = useContext(ContextTheme);
  auth.currentUser == null
    ? (user = null)
    : (user = users.find((x) => x.id === auth.currentUser.uid));
  // const user = users.find((x) => x.id.toString() === auth.currentUser.uid);
  const navigate = useNavigate();

  const location = useLocation();
  const categoria = location.state;
  const cuentaId = useParams();
  const cursoId = cuentaId.id;
  const cuenta = catalago.find((x) => x.id.toString() === cuentaId.id);
  // console.log(cuenta);
  const myReviews = reviews.filter((r) => r.data.cursoId === cursoId);

  let userName = "";
  let userId = "";
  let thisReview = [];

  if (user) {
    userName = user.data.name;
    userId = user.id;
    thisReview = myReviews.find((x) => x.data.userId === userId);
    updaterating(cursoId, reviews);
  } else {
    userName = "";
    userId = "";
    thisReview = [];
  }
  const myFotos = fotosgaleria.filter(
    (r) => r.data.cursoId === cursoId && r.data.userId === userId
  );
  const [addFotos, setAddFotos] = useState(false);

  const addComments = () => {
    if (!user) {
      toast.warning(CustomToast);
      // navigate("/sign-up");
    } else {
      navigate("/agregar-calificacion", {
        state: { thisReview, Id: cuentaId.id, categoria },
      });
    }
  };

  const CustomToast = () => (
    <div>
      <p style={{ color: "red", fontSize: ".8em", marginBottom: "30px" }}>
        Inicia Sesión y/o Crea Una Cuenta para Agregar comentario y/o
        Calificacion
      </p>
      <Button
        variant='outline-warning'
        onClick={() =>
          navigate("/sign-up", {
            state: { categoria },
          })
        }
      >
        <FaUserPlus /> Crear tu Cuenta
      </Button>
    </div>
  );

  const addPictures = () => {
    if (!user) {
      toast.warning(CustomToast);
      // navigate("/sign-up");
    } else {
      setAddFotos(true);
    }
  };

  return (
    <div className='animated-gradient '>
      <Container>
        <Link
          className='btn btn-light my-3'
          state={{ categoria: categoria }}
          to='/catalagodisplay'
        >
          Go Back
        </Link>
        <Col className='cuentaScreenCol'>
          <div>
            <Image
              className='cuentaScreenImg'
              // className='productscreenImage'
              src={
                cuenta.data.cuentaImage ? cuenta.data.cuentaImage : imagehere
              }
              // src={cuenta.data.cuentaImage}
              alt={cuenta.name}
              fluid
            />
          </div>
          <div>
            <ListGroup variant='flush'>
              <ListGroup.Item className='listctascreen'>
                <h2>${cuenta.data.duracion}.00</h2>
                <h5>{cuenta.data.especialidad}</h5>
              </ListGroup.Item>
              <ListGroup.Item className='listctascreen'>
                <Rating
                  value={cuenta.data.rating}
                  text={` ${cuenta.data.numReviews} `}
                />
              </ListGroup.Item>
              {/* <ListGroup.Item className='listctascreen'>
                Price: $ {cuenta.data.duracion} Dls
              </ListGroup.Item> */}
              <ListGroup.Item className='listctascreen'>
                Descripción:
              </ListGroup.Item>
              <ListGroup.Item className='listctascreen'>
                {cuenta.data.objetivo}
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Col>
        <Col className='cuentaScreenRev'>
          <h2>Comments and Reviwes</h2>
          {myReviews.length === 0 ? (
            <h3 className=' text-light'>Not Comments or Reviews yet...</h3>
          ) : (
            <Carousel variant='dark'>
              {myReviews.map((review) => {
                return (
                  <Carousel.Item key={review.id}>
                    <div className='cuentaCarouselComent'>
                      <div>
                        <Image
                          fluid
                          className=' mx-4  header-user-pic '
                          src={
                            review.data.userImage
                              ? review.data.userImage
                              : profile
                          }
                          alt='First slide'
                        />
                      </div>
                      <strong style={{ marginLeft: "20px" }}>
                        {review.data.userName}
                      </strong>
                      <div>
                        <Rating value={review.data.rating} />
                        <p>{review.data.comment}</p>
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          )}
        </Col>
        <Col style={{ display: "flex", justifyContent: "space-between" }}>
          {!addFotos && (
            <>
              <Button
                className='cuentaScreenBtn'
                variant='outline-success'
                onClick={addComments}
              >
                Add Comment and Rate the piece
              </Button>
              <Button
                className='cuentaScreenBtn'
                variant='outline-success'
                // onClick={addFotos}
                onClick={addPictures}
              >
                Add pictures to this piece
              </Button>
            </>
          )}
        </Col>
        {addFotos && (
          <AddPicturesGallery
            myFotos={myFotos}
            cursoId={cursoId}
            userName={userName}
            userId={userId}
          />
        )}
      </Container>
    </div>
  );
};

export default CuentaScreen;
