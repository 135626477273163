import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCANfmdPaJiR66u-oux47lFXPdo23fA79w",
  authDomain: "frieda-gallery-v2.firebaseapp.com",
  projectId: "frieda-gallery-v2",
  storageBucket: "frieda-gallery-v2.appspot.com",
  messagingSenderId: "768534756759",
  appId: "1:768534756759:web:51a2b101c8a85dba17d8d2",
};

// ********* old files source ****************

// const firebaseConfig = {
//     apiKey: "AIzaSyDi1fOyrM6OCnVJG7gNT-Ss2WtQ0v_7aKE",
//     authDomain: "prodecservices-v2.firebaseapp.com",
//     projectId: "prodecservices-v2",
//     storageBucket: "prodecservices-v2.appspot.com",
//     messagingSenderId: "174632361085",
//     appId: "1:174632361085:web:f58f2afc397ffeecb0a514",
// };

initializeApp(firebaseConfig);
export const db = getFirestore();
export const auth = getAuth();
