import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ContextTheme } from "../context/ContextTheme";
import { Container, Col, Row, Card } from "react-bootstrap";

const Inicio = () => {
  const { categorias } = useContext(ContextTheme);

  // ******ordernar categorias por tiempo *****
  const mycate = categorias.slice(0).reverse();

  return (
    <main>
      <Container>
        <h1>Categories</h1>

        {categorias.length === 0 ? (
          <h2>Add Category</h2>
        ) : (
          <Row className='testimoniosRow'>
            {mycate.map((categoria) => (
              <Col
                xs={6}
                lg={6}
                key={categoria.id}
                style={{ marginBottom: "1.5em" }}
              >
                <Link
                  to='/catalagodisplay'
                  state={{ categoria: categoria.data.categoria }}
                >
                  <Card
                    bg={"info"}
                    style={{
                      opacity: ".8",

                      justifyContent: "center",
                      alignItems: "center",
                      height: "10rem",
                    }}
                    className=' rounded'
                    // onClick={() => {
                    //   navigate("/catalagodisplay", { state: { categoria } });
                    // }}
                  >
                    <Card.Body className='testiCardBody'>
                      <Card.Text style={{ fontSize: "1em" }}>
                        {categoria.data.categoria}
                        {/* {console.log(categoria.id)} */}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </main>
  );
};

export default Inicio;
