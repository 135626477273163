import React, { useRef, useState, useContext } from "react";
import { ContextTheme } from "../context/ContextTheme";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { getAuth } from "firebase/auth";
import { Link } from "react-router-dom";
import ProfilePic from "../images/profile.jpg";
import { ImProfile } from "react-icons/im";
import {
  userUpdatePro,
  updateUserPic,
  delOldPic,
} from "../utils/UserFunctions";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const Profile = () => {
  const auth = getAuth();
  const { users } = useContext(ContextTheme);
  const user = users.find((x) => x.id.toString() === auth.currentUser.uid);
  const navigate = useNavigate();
  const myPic = useRef();
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState("");
  const [preview, setPreview] = useState("");
  const [FormData, setFormData] = useState({
    userId: user.id,
    name: user.data.name,
    userImage: user.data.userImage,
    userCompany: user.data.userCompany,
    userTitle: user.data.userTitle,
  });
  console.log(file);

  const userOldPic = user.data.userImgRef;

  const { name, userId, userImage, userCompany, userTitle } = FormData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    userUpdatePro(userId, name, userCompany, userTitle);
    if (file === null) {
      navigate("/");
    } else {
      delOldPic(userOldPic);
      updateUserPic(fileName, file, userId, setProgress);
      navigate("/");
    }
  };

  return (
    <>
      <Container>
        <h2>
          Profile <ImProfile style={{ marginLeft: "1em" }} />
        </h2>
        <Link className='btn btn-light my-3' to='/'>
          Go Back
        </Link>
        {/* {userImage ? console.log("yes") : console.log("not")} */}
        <div
          className={userImage ? "profDivPics" : "profDivPic"}
          // style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <div className={userImage ? "profileImage" : ""}>
            <Form.Label>
              {userImage ? (
                <Image
                  fluid
                  className='profile-user-pic'
                  onClick={() => {
                    setFile(null);
                  }}
                  src={userImage}
                />
              ) : (
                ""
              )}
            </Form.Label>
          </div>
          <Form.Group className='profileImage'>
            <Form.Label>
              {preview ? (
                <Image
                  fluid
                  className='profile-user-pic'
                  onClick={() => {
                    setFile(null);
                  }}
                  src={preview}
                />
              ) : (
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    myPic.current.click();
                  }}
                >
                  <Image
                    className='profile-user-pic'
                    fluid
                    src={ProfilePic}
                    alt=''
                  />
                </Button>
              )}
            </Form.Label>
            <Form.Control
              ref={myPic}
              accept='image/*'
              style={{ display: "none" }}
              type='file'
              id='file'
              name='file'
              onChange={(event) => {
                const file = event.target.files[0];

                if (file && file.type.substr(0, 5) === "image") {
                  setFile(file);
                  setFileName(`${uuidv4()}-${file.name}`);
                } else {
                  setFile(null);
                }
                if (file) {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const Base64 = reader.result;
                    setPreview(Base64);
                  };
                  reader.readAsDataURL(file);
                } else {
                  setPreview(null);
                }
              }}
            ></Form.Control>
            <progress
              style={{ marginLeft: "-2em" }}
              value={progress}
              max='100'
            />
          </Form.Group>
        </div>
        <Row style={{ justifyContent: "center" }}>
          <Col sm={12} md={8} lg={6} xxl={4}>
            <Form onSubmit={onSubmit}>
              <Form.Group className='mb-3'>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type='name'
                  id='name'
                  value={name}
                  placeholder='Tu Nombre'
                  onChange={onChange}
                />
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label>Compañia</Form.Label>
                <Form.Control
                  type='userCompany'
                  id='userCompany'
                  value={userCompany}
                  placeholder='Nombre de la empresa donde Trabajas'
                  onChange={onChange}
                />
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label>Titulo o Puesto</Form.Label>
                <Form.Control
                  type='userTitle'
                  id='userTitle'
                  value={userTitle}
                  placeholder='Puesto que desempeñas'
                  onChange={onChange}
                />
              </Form.Group>

              <Button
                variant='success'
                style={{ width: "100%", marginTop: "3em" }}
                type='submit'
              >
                actualizar Informacion
              </Button>
              <div
                style={{
                  padding: "2em 0 2em 0",
                  textAlign: "end",
                }}
              ></div>
            </Form>
            <div
              style={{
                padding: "2em 0 2em 0",
                textAlign: "center",
              }}
            ></div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
