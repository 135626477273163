import { Container, Alert, Button } from "react-bootstrap";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useState, useCallback, useContext, useEffect } from "react";
import { ContextTheme } from "../context/ContextTheme";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAuth } from "firebase/auth";
// import { v4 as uuidv4 } from "uuid";
import { createEvent, delEvent } from "../utils/eventosFunction";
import { toast } from "react-toastify";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function App() {
  const auth = getAuth();
  const { users } = useContext(ContextTheme);
  const user = users.find((x) => x.id.toString() === auth.currentUser.uid);
  const userId = user.id;
  const { eventos } = useContext(ContextTheme);
  console.log(eventos);
  const [allEvents, setAllEvents] = useState(eventos);
  const [msgTitle, setMsgTitle] = useState("");
  const [msgDescription, setMsgDescrition] = useState("");
  const [Id, setId] = useState("");
  const [show, setShow] = useState(false);

  const [FormData, setFormData] = useState({
    title: "",
    description: "",
    start: "",
    end: "",
  });

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value.toUpperCase(),
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    createEvent(FormData, userId);
    setFormData({ title: "", description: "", start: "", end: "" });
    setAllEvents(eventos);
  };

  useEffect(() => {
    setAllEvents(eventos);
  }, [eventos]);

  const { title, description, start, end } = FormData;

  const onSelectEvent = useCallback((calEvent) => {
    console.log(calEvent.id);
    setMsgDescrition(calEvent.description);
    setMsgTitle(calEvent.title);
    setId(calEvent.id);
    setShow(true);
  }, []);

  const delEvento = (Id) => {
    setShow(false);
    toast.error("Borrando categoria ");
    delEvent(Id);
    setAllEvents(eventos);
  };

  return (
    <Container>
      <form onSubmit={onSubmit}>
        <div
          style={{
            backgroundColor: "azure",
            marginTop: "2em",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h3>Add New Event</h3>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              type='text'
              placeholder='Add Title'
              id='title'
              style={{ width: "40%" }}
              value={title}
              onChange={onChange}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              type='text'
              id='description'
              placeholder='description'
              style={{ width: "60%" }}
              value={description}
              onChange={onChange}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DatePicker
              id='start'
              placeholderText='Start Date'
              selected={start}
              value={start}
              // onChange={onChange}
              onChange={(start) => setFormData({ ...FormData, start })}
            />
            <DatePicker
              id='end'
              placeholderText='End Date'
              selected={end}
              value={end}
              onChange={(end) => setFormData({ ...FormData, end })}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button style={{ width: "50%", marginBottom: "8em" }} type='submit'>
              Add Event
            </button>
            <Alert
              show={show}
              variant='success'
              style={{ position: "absolute", width: "50%" }}
            >
              <Alert.Heading>{msgTitle}</Alert.Heading>
              <p>{msgDescription}</p>
              <hr />
              <div className='d-flex justify-content-end'>
                <Button
                  style={{ width: "6em", margin: "0 5px", fontSize: ".8em" }}
                  onClick={() => setShow(false)}
                  variant='outline-success'
                >
                  close
                </Button>
                <Button
                  style={{ width: "8em", margin: "0 5px", fontSize: ".6em" }}
                  variant='outline-danger'
                  onClick={() => delEvento(Id)}
                >
                  delete
                </Button>
              </div>
            </Alert>
          </div>

          <div>
            <Calendar
              views={["month", "agenda"]}
              toolbar={[""]}
              localizer={localizer}
              events={allEvents}
              startAccessor='start'
              endAccessor='end'
              style={{ height: 500, margin: "10px" }}
              popup={true}
              onSelectEvent={onSelectEvent}
            />
          </div>
        </div>
      </form>
    </Container>
  );
}

export default App;
