import React, { useState } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { BsBoxArrowRight } from "react-icons/bs";
import { MdVisibility } from "react-icons/md";
import { FaUserPlus } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { SignUpFun } from "../utils/UserFunctions";

const SignUp = () => {
    const [showPassword, setShowPassword] = useState(false);

    const [FormData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
    });

    const { name, email, password } = FormData;

    const navigate = useNavigate();

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        SignUpFun(name, email, password, FormData);
        navigate("/");
    };

    return (
        <main className='main'>
            <Container>
                <h2 style={{ marginBottom: "4em" }}>
                    Registra tu Cuenta <FaUserPlus />
                </h2>
                <Row className='signinbody'>
                    <Col sm={12} md={8} lg={6} xxl={4}>
                        <Form onSubmit={onSubmit}>
                            <Form.Group className='mb-3'>
                                <Form.Label>Tu Nombre </Form.Label>
                                <Form.Control
                                    type='text'
                                    id='name'
                                    value={name}
                                    placeholder='Ingresa tu Nombre'
                                    onChange={onChange}
                                />
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Correo Electronico</Form.Label>
                                <Form.Control
                                    type='email'
                                    id='email'
                                    value={email}
                                    placeholder='Ingresa tu correo electronico'
                                    onChange={onChange}
                                />
                                <Form.Text className='text-muted fst-italic'>
                                    nunca compartiremos su correo electrónico
                                    con nadie más.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Label>Contraseña</Form.Label>
                                <Form.Control
                                    id='password'
                                    value={password}
                                    type={showPassword ? "text" : "password"}
                                    placeholder='Password'
                                    onChange={onChange}
                                />
                                <div style={{ textAlign: "end" }}>
                                    <MdVisibility
                                        onClick={() =>
                                            setShowPassword(
                                                (prevState) => !prevState
                                            )
                                        }
                                        fill='green'
                                        style={{
                                            height: "1.5em",
                                            width: "1.5em",
                                            cursor: "pointer",
                                        }}
                                    />
                                    {"  "}
                                    ver Contraseña
                                </div>
                            </Form.Group>

                            <Button variant='primary' type='submit'>
                                Crear tu Cuenta
                            </Button>
                        </Form>
                        <div
                            style={{
                                padding: "2em 0 2em 0",
                                textAlign: "center",
                            }}
                        >
                            <Link
                                style={{ color: "gray" }}
                                className='signinforgatPasswordLink'
                                to='/sign-in'
                            >
                                Si ya estas registrado ? Iniciar Secion...{" "}
                                <BsBoxArrowRight />
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </main>
    );
};

export default SignUp;
