import React, { useContext } from "react";
import { Button, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import CatalagoList from "../components/CatalagoList";
import { ContextTheme } from "../context/ContextTheme";

const Catalago = () => {
  const { categorias } = useContext(ContextTheme);

  return (
    <>
      <Container>
        <h2 className='my-4'>Catalago de Cursos </h2>
        <Link className='btn btn-light my-3' to='/'>
          Go Back
        </Link>
        <Col style={{ display: "flex", justifyContent: "end" }}>
          <Link to='/admin/CrearCuenta'>
            <Button pd='8px 12px' type='submit' hbg='#ebfbff'>
              Agredar Un Nuevo Curso
            </Button>
          </Link>
        </Col>
        <hr />
        <>
          <ol
            style={{
              marginRight: ".5em",
              marginLeft: ".5em",
              padding: "0em",
            }}
          >
            {categorias.map((cat) => (
              <CatalagoList id={cat.id} key={cat.id} cat={cat} />
            ))}
          </ol>
        </>
      </Container>
    </>
  );
};

export default Catalago;
