import React, { useContext, useState } from "react";
import { ListGroup, Badge, Image, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsArrowDown } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import ProfilePic from "../images/profile.jpg";
import { ContextTheme } from "../context/ContextTheme";
import { delNewInfoRequest } from "../utils/otherFuntioins";

const InfoPreciosDisp = () => {
  const { infoPrice } = useContext(ContextTheme);
  const [newCatId, setNewCatId] = useState("");
  const [moreInfo, setMoreInfo] = useState(false);

  const deleteCurso = () => {
    delNewInfoRequest(newCatId);
  };

  return (
    <>
      <Container>
        <Link className='btn btn-light my-3' to='/'>
          Go Back
        </Link>
        <h2 className='my-4'>Lista de Solicitudes de informacion </h2>
        <hr />
        <>
          <ol>
            {infoPrice.map((InfoReq) => (
              <ListGroup.Item
                style={{ flexWrap: "wrap" }}
                className='catalagoli'
                key={InfoReq.id}
              >
                <Image
                  style={{ marginRight: ".8em" }}
                  className='header-user-pic'
                  src={InfoReq.data.userPic ? InfoReq.data.userPic : ProfilePic}
                  alt=''
                />
                <p className='infoPreDispP'>{InfoReq.data.nombre}</p>
                <p className='infoPreDispP'>{InfoReq.data.telefono}</p>
                <div>
                  <Badge
                    bg='info'
                    pill
                    style={{
                      marginRight: "1em",
                      fontSize: "1em",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setNewCatId(InfoReq.id);
                      setMoreInfo((prevState) => {
                        if (InfoReq.id === newCatId) {
                          return !prevState;
                        }
                      });
                    }}
                  >
                    <BsArrowDown />
                  </Badge>
                  <Badge
                    bg='danger'
                    pill
                    style={{
                      marginRight: "1em",
                      fontSize: "1em",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setNewCatId(InfoReq.id);
                      deleteCurso();
                    }}
                  >
                    <RiDeleteBinLine />
                  </Badge>
                </div>
                {moreInfo ||
                  (InfoReq.id === newCatId && (
                    <>
                      <div className='catalagoliextra'>
                        <div>
                          <p>{InfoReq.data.pieceName}</p>
                        </div>
                        <div>
                          <p>{InfoReq.data.email}</p>
                        </div>
                      </div>
                      <div className='catalagoliextra'>
                        <p>{InfoReq.data.comment}</p>
                      </div>
                    </>
                  ))}
              </ListGroup.Item>
            ))}
          </ol>
        </>
      </Container>
    </>
  );
};

export default InfoPreciosDisp;
