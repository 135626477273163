import {
    addDoc,
    collection,
    doc,
    deleteDoc,
    updateDoc,
    serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase.confirg";
import { toast } from "react-toastify";

export const createCat = async (FormData, userId) => {
    const formDataCopy = {
        ...FormData,
        userId,
        timestamp: serverTimestamp(),
    };
    await addDoc(collection(db, "categorias"), formDataCopy);
};

export const updateCat = async (catId, newCategoria) => {
    const docRef = doc(db, "categorias", catId);

    await updateDoc(docRef, {
        categoria: newCategoria,
    })
        .then(() => {
            toast("Categoria Modificada ...");
        })
        .catch((err) => {
            toast.error("No se pudo Registrar Categoria", err);
        });
};

export const delCat = async (catId) => {
    deleteDoc(doc(db, "categorias", catId));
};
