import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    updateProfile,
    sendPasswordResetEmail,
} from "firebase/auth";
import {
    setDoc,
    doc,
    updateDoc,
    getDoc,
    serverTimestamp,
} from "firebase/firestore";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
} from "firebase/storage";
import { db } from "../firebase.confirg";
import { toast } from "react-toastify";

const auth = getAuth();

const storage = getStorage();

export const SignInFun = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
        );
        if (userCredential.user) {
            // navigate("/");
            window.location.reload();
        }
    } catch (error) {
        toast.error("los Datos no son Correctios...");
        console.log(error);
    }
};

export const signOutFun = () => {
    auth.signOut();
    window.location.reload();
};

export const SignUpFun = async (name, email, password, FormData) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
        );
        const user = userCredential.user;

        updateProfile(auth.currentUser, {
            displayName: name,
        });

        const formDataCopy = { ...FormData };
        delete formDataCopy.password;
        formDataCopy.timeStamp = serverTimestamp();
        formDataCopy.userId = user.uid;
        formDataCopy.userImage = "";
        formDataCopy.userImgRef = "";
        formDataCopy.userCompany = "";
        formDataCopy.userTitle = "";
        formDataCopy.userRol = 0;

        await setDoc(doc(db, "users", user.uid), formDataCopy);

        // navigate("/");
        // window.location.reload();
    } catch (error) {
        toast.error("no se pudo Registrar el Usuario ");
    }
};

export const getrole = (id, setUserRole) => {
    const docRef = doc(db, "users", id);
    getDoc(docRef).then((doc) => {
        setUserRole(doc.data().userRol);
    });
};

export const forgotPass = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        toast.success("Email was sent");
    } catch (error) {
        toast.error("Could not send reset email");
    }
};

export const updateUserPic = (fileName, file, userId, setProgress) => {
    const metadata = {
        contenType: "image/*",
    };
    const storageRef = ref(storage, `userImages/${fileName}`);

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
                case "paused":
                    console.log("Upload is paused");
                    break;
                case "running":
                    console.log("Upload is running");
                    break;
                default:
            }
        },
        (error) => {
            switch (error.code) {
                case "storage/unauthorized":
                    console.log(error.code);
                    // User doesn't have permission to access the object
                    break;
                case "storage/canceled":
                    // User canceled the upload
                    break;

                // ...

                case "storage/unknown":
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                default:
            }
        },
        () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                updateProfile(auth.currentUser, {
                    photoURL: downloadURL,
                });
                const updateUserImage = async () => {
                    const docRef = doc(db, "users", userId);

                    const prodPayload = {
                        userImage: downloadURL,
                        userImgRef: fileName,
                    };

                    await updateDoc(docRef, prodPayload)
                        .then(() => {
                            toast("Fotografia de Usuario Actualizada");
                        })
                        .catch((err) => {
                            toast.err("Algo salio mal ...", err);
                        });
                };
                updateUserImage();
            });
        }
    );
};

export const userUpdatePro = (userId, name, userCompany, userTitle) => {
    if (!userCompany) {
        userCompany = "";
    }
    if (!userTitle) {
        userTitle = "";
    }
    const updateUserImage = async () => {
        const docRef = doc(db, "users", userId);

        const prodPayload = { name, userCompany, userTitle };

        await updateDoc(docRef, prodPayload)
            .then(() => {
                toast("Datos de Usuario Actualizando...");
            })
            .catch((err) => {
                toast.err("Algo salio mal ...", err);
            });
    };
    updateUserImage();
};

export const delOldPic = (userOldPic) => {
    console.log(userOldPic);
    if (userOldPic === "") {
        toast("No exite foto de perfil");
    } else {
        try {
            deleteObject(userOldPic);
            toast("Borrando foto de Perfil");
        } catch (error) {
            toast.error("no se pudo borrar la foto..");
        }
    }
};
