import React, { useContext, useState } from "react";
import { ContextTheme } from "./context/ContextTheme";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import CatalagoDisplay from "./components/CatalagoDisplay";
import Inicio from "./screens/Inicio";
import AddPictureProfile from "./components/AddPictureProfile";
import SignIn from "./screens/SignIn";
import SignUp from "./screens/SignUp";
import AlertMsg from "./components/AlertMsg";
import Profile from "./screens/Profile";
import GaleriaFotos from "./screens/GaleriaFotos";
import InfoPrecios from "./screens/InfoPrecios";
import QuienesSomos from "./screens/QuienesSomos";
import Calendar from "./screens/Calendar";
import Testimonios from "./screens/Testimonios";
import ForgotPassword from "./screens/ForgotPassword";
import Categorias from "./screens/Categorias";
import Catalago from "./screens/Catalago";
import CrearCuenta from "./screens/CrearCuenta";
import CuentaScreen from "./screens/CuentaScreen";
import AgregarCalificacioin from "./screens/AgregarCalificacioin";
import AgregarFotos from "./screens/AgregarFotos";
import EditCuenta from "./screens/EditCuenta";
import Footer from "./components/Footer";
import NotFound from "./screens/NotFound";
import { getrole } from "./utils/UserFunctions";
import InfoPreciosDisp from "./screens/InfoPreciosDisp";
import Clientes from "./screens/Clientes";
import AddGaleria from "./screens/AddGaleria";
import { getAuth } from "firebase/auth";

const App = () => {
  const auth = getAuth();
  let user = "";
  const { users } = useContext(ContextTheme);
  auth.currentUser == null
    ? (user = null)
    : (user = users.find((x) => x.id === auth.currentUser.uid));

  const { infoPrice } = useContext(ContextTheme);
  const myinfoRequest = infoPrice.filter((r) => r.data.status === 1);

  const [userRole, setUserRole] = useState(0);

  if (user) {
    const id = user.id;
    getrole(id, setUserRole);
  }
  return (
    <div>
      <Header />
      {user && userRole === 1 && myinfoRequest && (
        <AlertMsg infoRequest={myinfoRequest} />
      )}
      <>
        <Routes>
          <Route path='/' exact={true} element={<Inicio />} />
          <Route path='/sign-in' element={<SignIn />} />
          <Route path='/add-picture-profile' element={<AddPictureProfile />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/galeria-fotos' element={<GaleriaFotos />} />
          <Route path='/info-precios' element={<InfoPrecios />} />
          <Route path='/quienes-somos' element={<QuienesSomos />} />
          <Route path='/calendar' element={<Calendar />} />
          <Route path='/testimonios' element={<Testimonios />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/cuenta/:id' element={<CuentaScreen />} />
          <Route path='/catalagodisplay' element={<CatalagoDisplay />} />
          <Route
            path='/agregar-calificacion'
            element={<AgregarCalificacioin />}
          />
          <Route path='/agregar-fotos' element={<AgregarFotos />} />
          <Route path='/admin/categorias' element={<Categorias />} />
          <Route path='/admin/catalago' element={<Catalago />} />
          <Route path='/admin/clientes' element={<Clientes />} />
          <Route path='/admin/add-galeria' element={<AddGaleria />} />
          <Route path='/admin/info-precio-list' element={<InfoPreciosDisp />} />
          <Route path='/admin/CrearCuenta/' element={<CrearCuenta />} />
          <Route path='/admin/editCuenta/:id' element={<EditCuenta />} />

          <Route path='*' element={<NotFound />} />
        </Routes>
      </>

      <Footer />
      <ToastContainer />
    </div>
  );
};

export default App;
